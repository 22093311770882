.dropdown {
  min-width: 11.375rem;
  .option {
    width: auto;
  }
}
.label {
  @apply capitalize flex whitespace-nowrap cursor-pointer;
  color: var(--black-1);
  .icon {
    margin-right: 0.5rem;
  }
  .number {
    @apply text-center;
    width: 1rem;
    margin: 0 0.1rem;
  }
}
.content {
  @apply flex gap-4;
  margin-top: 1rem;
  padding: 0.625rem;
  background-color: var(--c-1);
}
