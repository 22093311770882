.desktop {
  display: block;
}
.mobile {
  display: none;
}
.menuIcon {
  @apply flex items-center;
  width: 6rem;
  position: absolute;
  padding-left: var(--pad-inner-child);
  margin-top: 40px;
  height: 88px;
  z-index: 100;
}
.header {
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  position: relative;
  &.toTop {
    transform: translateY(-100%);
  }
  mix-blend-mode: normal;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  .headerBg {
    background: rgb(0, 0, 0);
  }
  .headerChild {
    min-width: 51.5625rem;

    height: var(--header-child-height);
    padding-left: 31px;
    .amanakiGroup {
      @apply uppercase;
      font-size: 0.75rem;
      line-height: 2.5rem;
      font-weight: 450;
      letter-spacing: 0.1em;
      padding: 0 1rem;
    }
    .border {
      border-right: 1px solid var(--c-1);
      margin: auto 0.5rem;
      height: 1rem;
    }
    .options {
      @apply whitespace-nowrap;
      width: auto;
      .option {
        padding-right: 1rem;
      }
    }
    .language {
      min-width: initial;
      .label {
        label {
          width: 20px;
        }
      }
      .option {
        @apply flex items-center;
      }
    }
  }

  .headerChild2 {
    background: transparent;
    // height: calc(100% - var(--header-child-height));
    height: var(--header-logo-height);
    .dropdown {
      .label {
        padding: 0;
        height: auto;
        &:after {
          display: none;
        }
      }
      .options {
        top: 3.5rem;
        right: 0;
        left: auto;
        overflow: initial;
        .option {
        }
      }
    }
  }
  .icon {
    opacity: 0;
  }
  .hotels {
    min-width: 12.5rem;
    .label {
      font-size: 0.75rem;
      line-height: initial;
      font-weight: 450;
      letter-spacing: 0.1em;
    }
  }
  .animBorderTop {
    border-top: 1px solid transparent;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  }
  .navs {
    @apply flex gap-8;
    height: var(--header-navs-height);
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 1.25rem;
    // border-top: 1px solid transparent;
    // transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    white-space: nowrap;
    .point {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #d9d9d9;
    }
    .options {
      @apply whitespace-nowrap;
      width: auto;
      .option {
        padding-right: 1rem;
      }
    }
    .navDropdown {
      min-width: initial;
    }
  }
  &.isGroup {
    background-color: black !important;
  }
  &.switched {
    background-color: black;
    .animBorderTop {
      border-top: 1px solid rgba(214, 214, 214, 0.5);
    }
    .navs {
      // border-top: 1px solid rgba(214, 214, 214, 0.5);
    }
  }
}
@media (max-width: 1600px) {
  .header {
    .navs {
      @apply gap-6;
    }
  }
}
@media (max-width: 1280px) {
  .header {
    .navs {
      @apply gap-4;
      font-size: 0.75rem;
    }
  }
}
// @media (max-width: 1023px) {
// }
@media (max-width: 576px) {
  .header {
    .headerChild {
      @apply hidden;
    }
    .headerChild2 {
      @apply flex-row-reverse;
      // background-color: black;
      .dropdown {
        display: none;
      }
    }
    .animBorderTop {
      @apply hidden;
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .menuIcon {
    @apply flex items-center justify-end;
    padding-right: var(--pad-inner-child);
    right: 0;
    margin-top: 0;
  }
}
