.section {
  padding-top: 3.375rem;
  .box {
    background-color: var(--c-1);
    padding: 3rem 0;

    .title {
    }
    .description {
      @apply text-center mx-auto;
      padding-top: 1.5rem;
      max-width: 43.25rem;
      font-weight: 400;
    }
    .button {
      @apply flex justify-center;
      padding-top: 1.5rem;
    }
    .valid {
      @apply text-center;
      padding-top: 1.5rem;
      font-weight: 400;
    }
  }
}

// @media (max-width: 1280px) {
// }
// @media (max-width: 1023px) {
// }
@media (max-width: 576px) {
  .section {
    padding-top: 1.5rem;
    padding-left: 0;
    padding-right: 0;
    .description {
      padding-left: var(--pad-inner);
      padding-right: var(--pad-inner);
    }
  }
}
