$hl-color: #ae905e;
$trans-time: 300ms;
.input {
  position: relative;
  @apply flex items-center;
  min-width: 6rem;
  background-color: var(--c-1);
  color: var(--black-1);
  font-family: var(--font-family);
  padding: 0.75rem;
  font-weight: 400;

  cursor: pointer;
  .prefix {
    margin-right: 8px;
  }
  input {
    @apply cursor-pointer;
    caret-color: transparent;
    background-color: var(--c-1);
    outline: none;
    width: 100%;

    &::placeholder {
      color: var(--black-1);
    }
    &:focus ~ .bar:before {
      width: 100%;
    }
  }
  .value {
    white-space: nowrap;
  }

  .bar {
    position: absolute;
    left: 0;
    bottom: -1px;
    display: none;
    width: 100%;
    &:before {
      content: "";
      height: 1px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $hl-color;
      transition: $trans-time ease all;
      left: 0%;
    }
  }
  &.border {
    border-bottom: 1px solid rgba(46, 40, 31, 0.3);
    .bar {
      display: block;
    }
  }
}
