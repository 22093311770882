.formBox {
  padding: 1.5rem;
  .item {
    padding-top: 0.5rem;
  }
  .body {
    .titleBox {
      @apply flex justify-between items-center;
      .title {
        @apply uppercase;
        color: var(--black-1);
        font-weight: 450;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.1em;
      }
    }
    .inputStyle {
      background-color: transparent;
      input {
        background-color: transparent;
      }
    }
    .people {
      padding-top: 0.5rem;
      padding-bottom: 0.25rem;
    }
  }

  .footer {
    @apply flex flex-col;
    .submit {
      @apply flex;
      padding-top: 1.25rem;
      width: 100%;
      .button {
        width: 100%;
      }
    }
    .link {
      margin-top: 1.25rem;
      @apply block text-center italic capitalize underline;
      color: var(--black-1);
    }
  }
  .select {
    .boxOption {
      .option {
        @apply capitalize;
        font-weight: 400;
        font-size: 0.875rem;
      }
    }
  }
}
.dropdown {
  @apply relative;
  .area {
    @apply absolute left-0 w-full z-10;
    width: 25.375rem;
    overflow: hidden;
    max-height: 0px;
    top: 2.5rem;
    background-color: #fff;
    right: 0;
    left: auto;

    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  }
  &.active {
    .area {
      max-height: 1000px;
      top: 3.5rem;
    }
  }
  &.overflowInitial {
    .area {
      overflow: initial;
    }
  }
}

// @media (max-width: 1280px) {
// }
// @media (max-width: 1023px) {
// }
@media (max-width: 576px) {
  .formBox {
    @apply flex flex-col justify-between h-full;
    height: 100%;

    .footer {
      margin-top: auto;
    }
  }
  .dropdown {
    .area {
      @apply fixed;
      width: 100%;
      height: 100%;
      top: 0;
      form {
        height: 100%;
      }
    }
    &.active {
      .area {
        top: 0;
      }
    }
  }
}
