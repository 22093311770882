.section {
  @apply flex flex-col gap-[2rem];
  padding-top: 4rem;
  .full {
    @apply w-full;
    .image {
      @apply w-full;
      aspect-ratio: 1440 / 500;
    }
  }
  .twoCols {
    @apply grid grid-cols-2 gap-10;
    .image {
      @apply w-full;
      aspect-ratio: 642 / 400;
    }
  }
  .threeCols {
    @apply grid grid-cols-3 gap-10;
    .image {
      @apply w-full;
      aspect-ratio: 419 / 400;
    }
  }
}
