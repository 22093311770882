.level-2 {
  @apply hidden;
}
.box {
  @apply flex-col justify-between h-full hidden overflow-x-scroll;
  .language {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .dropdown {
    // width: 8rem;
    // .currentItem {
    //   padding-left: 0;
    //   padding-right: 0;
    //   font-weight: 420;
    // }
    min-width: initial;
    .currentItem {
      label {
        width: 20px;
      }
    }
    .option {
      @apply flex items-center;
    }
  }
  .navs {
    border-top: 1px solid rgba(#f7eedf, 0.3);
    padding-top: 1rem;
    padding-bottom: 1rem;
    .title {
      font-size: 1.5rem;
      line-height: 1.875rem;
      color: var(--yellow-1);
      margin: 1.375rem 0;
    }
    .nav {
      @apply uppercase cursor-pointer;
      line-height: 2.5rem;
      font-weight: 420;
      font-size: 1rem;
      .arrow {
        margin-left: 1rem;
      }
    }
  }
  .booking {
    border-top: 1px solid rgba(#f7eedf, 0.3);
    padding-top: 2rem;
    padding-bottom: 2rem;
    .dropdown {
      width: 100%;
      .label {
        width: 100%;
      }
    }
  }
  .hotel {
    border-top: 1px solid rgba(#f7eedf, 0.3);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .info {
    border-top: 1px solid rgba(#f7eedf, 0.3);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .button {
    width: 100%;
  }
}
.divider {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (max-width: 576px) {
  .box {
    @apply flex;
  }
  .level2 {
    @apply flex-col;
    opacity: 0;
    transform: translateY(100%);
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    &.showSub {
      opacity: 1;
      transform: translateY(0%);
    }
    .control {
      @apply flex justify-between;
      padding-top: 4rem;
    }
    .title {
      @apply capitalize text-center;
      font-family: var(--font-family-secondary);
      padding-top: 3.75rem;
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .subnavs {
      @apply relative;
      margin-top: 2rem;
      height: 100%;
      .boxNav {
        padding-top: 5.125rem;
        @apply absolute flex flex-col gap-6 inset-0 z-30;
      }
      .nav {
        width: 100%;
      }
      .image {
        @apply absolute inset-0 z-10;
      }
    }
  }
}
