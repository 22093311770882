.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.close {
  @apply mr-2 text-white opacity-0;
  font-size: 0.85rem;
  transform: opacity 0.4 ease-in-out;
}
.close.show {
  @apply opacity-100;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.2rem;
  width: 1.5rem;
}

.checkmark span {
  width: 28px;
  height: 1px;
  left: 0;
  background-color: white;
  position: absolute;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.checkmark span:nth-child(1) {
  top: 10%;
  width: 12px;
}

.checkmark span:nth-child(2) {
  top: 40%;
  width: 24px;
}

.checkmark span:nth-child(3) {
  top: 70%;
  width: 18px;
}

.container.show {
  .checkmark span:nth-child(1) {
    width: 28px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    -webkit-transform: translateY(-50%) rotate(45deg);
    -moz-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    -o-transform: translateY(-50%) rotate(45deg);
  }
}

.container.show {
  .checkmark span:nth-child(2) {
    top: 50%;
    width: 28px;
    transform: translateY(-50%) rotate(-45deg);
    -webkit-transform: translateY(-50%) rotate(-45deg);
    -moz-transform: translateY(-50%) rotate(-45deg);
    -ms-transform: translateY(-50%) rotate(-45deg);
    -o-transform: translateY(-50%) rotate(-45deg);
  }
}

.container.show {
  .checkmark span:nth-child(3) {
    // transform: translateX(-50px);
    // -webkit-transform: translateX(-50px);
    // -moz-transform: translateX(-50px);
    // -ms-transform: translateX(-50px);
    // -o-transform: translateX(-50px);
    opacity: 0;
  }
}
