.section {
  @apply flex flex-col items-center relative;
  background: var(--bg-black-color);
  width: 100vw;
  .mainInfoFooterWrapper {
    width: 100%;
    border-top: 1px solid rgb(247, 238, 223, 0.3);
    padding: 30px;
    .mainInfoFooter {
      @apply grid mx-auto relative;
      width: 100%;
      max-width: 1200px;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 24px;
      column-gap: 16px;
      .logo {
        width: 97px;
        height: 101px;
      }
      .address {
        grid-column: 2 / span 2;
        @apply flex flex-col;
        row-gap: 12px;
        .name {
          font-family: "Brandon Text";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: #af915e;
        }
        .value {
          font-family: "Brandon Text";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          text-transform: capitalize;
          color: #f7eedf;
        }
      }
      .phone {
        @apply flex items-center;
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        .value {
          font-family: "Brandon Text";
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 19px;
          color: #f7eedf;
        }
      }
      .email {
        @apply flex items-center;
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        .value {
          font-family: "Brandon Text";
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 19px;
          color: #f7eedf;
        }
      }
      .socialGroup {
        @apply flex;
        gap: 14px;
      }
      .expandInfo {
        .label {
          @apply flex items-center justify-between;
          .text {
            font-family: "Brandon Text";
            font-style: normal;
            font-weight: 450;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #af915e;
          }
          .icon {
            @apply hidden;
          }
        }
        .content {
          margin-top: 16px;
          @apply flex flex-col;
          gap: 16px;
          .value {
            font-family: "Brandon Text";
            font-style: normal;
            font-weight: 450;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #f7eedf;
          }
        }
      }
    }
  }
  .copyrightSectionWrapper {
    width: 100%;
    border-top: 1px solid rgb(247, 238, 223, 0.3);
    padding: 20px 30px;
    height: auto;
    .copyrightSection {
      @apply flex justify-between mx-auto;
      width: 100%;
      max-width: 1200px;
      font-family: "Brandon Text";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #f7eedf;
    }
  }
  .backToTop {
    @apply top-0 right-0 flex items-center;
    right: 0;
    top: 0;
    bottom: 0;
    letter-spacing: 0.1em;
    font-size: 12px;
    margin-right: -5.5rem;
  }
  .backToTopMobile {
    display: none;
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
  .section {
    .mainInfoFooterWrapper {
      padding: 32px 0;
      .mainInfoFooter {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        row-gap: 16px;
        column-gap: 0;
        .address {
          margin-top: 16px;
          grid-column: unset;
          .name {
            text-align: center;
          }
          .value {
            max-width: 245px;
            text-align: center;
          }
        }
        .socialGroup {
          @apply justify-center;
          width: 100%;
          border-bottom: 1px solid rgb(247, 238, 223, 0.3);
          padding-top: 16px;
          padding-bottom: 32px;
          margin-bottom: 16px;
        }
        .expandInfo {
          width: 100%;
          padding: 0 16px;
          .label {
            .icon {
              @apply block;
            }
          }
        }
      }
    }
    .copyrightSectionWrapper {
      @apply relative flex justify-between;
      padding: 32px 16px;
      height: 210px;
      .copyrightSection {
        @apply flex-col justify-start;
        gap: 16px;
      }
    }
    .backToTop {
      display: none;
      margin-right: -3rem;
    }
    .backToTopMobile {
      @apply flex items-center;
      // width: 12.5rem;
      letter-spacing: 0.1em;
      font-size: 12px;
      margin-right: -3rem;
    }
  }
}
@media (max-width: 576px) {
}
