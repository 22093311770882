.box {
  @apply flex flex-col;
  label {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;
    color: var(--black-1);
  }
  .action {
    @apply flex;
    .button {
      @apply flex items-center justify-center;
      width: 2.75rem;
      height: 2.75rem;
      background-color: var(--black-1);
    }
    .number {
      @apply flex items-center justify-center;
      width: 3rem;
      height: 2.75rem;
      border: 1px solid var(--black-1);
      color: var(--black-1);
    }
  }
}
