$hl-color: #ae905e;
$trans-time: 300ms;
.dropdown {
  @apply relative;
  min-width: 120px;
  .currentItem {
    @apply flex items-center uppercase cursor-pointer;
    label {
      cursor: pointer;
    }
    height: 2.5rem;
    padding: 0 1rem;
    padding-right: 2rem;
    &:after {
      content: "\003E";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%) rotate(90deg) scaleY(1.75);
      color: var(--primary-color);
      font-size: 12px;
      pointer-events: none;
      z-index: 2;
      transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
    }
    &.noArrow:after {
      display: none;
    }
  }
  .options {
    @apply absolute left-0 w-full z-10;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    top: 2rem;
    max-height: 0px;
    overflow: hidden;
    width: max-content;

    .option {
      @apply uppercase block cursor-pointer;
      label {
        cursor: pointer;
      }
      height: 0;
      color: var(--primary-color);
      background-color: var(--yellow-1);
      font-size: 0.75rem;
      line-height: 2.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
      &.selected {
        height: 2.5rem;
        line-height: 2.5rem;
      }
    }
  }
  .bar {
    position: absolute;
    left: 0;
    bottom: -1px;
    display: none;
    width: 100%;
    &:before {
      content: "";
      height: 1px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $hl-color;
      transition: $trans-time ease all;
      left: 0%;
    }
  }
  &.border {
    border-bottom: 1px solid rgba(46, 40, 31, 0.3);
  }
  &.active {
    &.border {
      .bar {
        display: block;
        &:before {
          width: 100%;
        }
      }
    }
    .currentItem {
      &:after {
        transform: translate(-50%, -50%) rotate(90deg) scaleX(-1) scaleY(1.75);
      }
    }
    .options {
      max-height: 1000px;
      top: 2.5rem;
      .option {
        height: 40px;
      }
    }
  }
  &.aminUnderline {
    @apply flex items-center;
    padding: 0 1rem;
    .currentItem {
      @apply relative;
      line-height: initial;
      height: initial;
      padding: 0;
      padding-right: 1.4rem;
      &:before {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        left: 0;
        content: "";
        display: block;
        height: 1px;
        transform: scaleX(0);
        width: 100%;
        position: absolute;
        background: #fff;
        transform-origin: bottom right;
        transition: transform 0.4s ease 0s, left 0.4s ease 0s;
      }
      &:hover:before {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}
