$yellow1: #ae905e;
$c1: #f6eedf;
$black1: #2e281f;
.buttonLink {
  @apply mx-auto flex;
}
.button {
  @apply relative flex justify-center items-center;
  padding: 1rem 1.875rem;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  .hiddenText {
    display: none;
  }
  &.aminText {
    @apply overflow-hidden;
    padding: 0;
    .hiddenText {
      @apply absolute inset-0;
      display: block;
      color: $c1;
      padding: 1rem 1.875rem;
      transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
      transform: translateY(-100%);
    }
    .displayText {
      display: block;
      color: $c1;
      padding: 1rem 1.875rem;
      transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    }
  }
  &.fold {
    background: linear-gradient(
          to left bottom,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 0
        )
        no-repeat 100% 0 / 1.5rem 1.5rem,
      linear-gradient(-135deg, transparent 1.08em, var(--yellow-1) 0);
  }

  &.default {
    border: 1px solid rgba($c1, 1);
    color: $c1;
    &.dark {
      border: 1px solid rgba($black1, 1);
      color: $black1;
    }
  }
  &.overlay {
    border: 1px solid rgba($c1, 1);
    color: $c1;
    &.dark {
      border: 1px solid rgba($c1, 1);
      color: $c1;
      background-color: rgba($black1, 0.1);
    }
  }
  &.primary {
    color: $black1;
    background-color: $yellow1;
    .hiddenText,
    .displayText {
      color: $black1;
    }
  }
  &.text {
    @apply relative border-none;
    padding: 1rem 0;
    color: $c1;
    font-weight: 400;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0.8rem;
      left: 0;
      background-color: $c1;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &.dark {
      color: $black1;
      &::after {
        background-color: $black1;
      }
    }
  }
  &.icon {
    @apply relative border-none;
    padding: 1rem 0;
    color: $c1;
    font-weight: 400;
    &.dark {
      color: $black1;
    }
    .suffix {
      @apply overflow-hidden relative flex justify-end;
      height: 14px;
      width: 51px;
      svg {
        @apply absolute;
        width: 51px;
        height: 14px;
        transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
      }
    }
  }
  &.textWhite {
    color: var(--c-1);
  }
  &:hover:not(.disabled) {
    filter: brightness(1.15);
    &.aminText {
      .hiddenText {
        transform: translateY(0%);
        color: $yellow1;
      }
      .displayText {
        color: $yellow1;
        transform: translateY(100%);
      }
    }
    &.default {
      color: $yellow1;
      background-color: $c1;
      &.dark {
        color: $c1;
        background-color: $black1;
      }
    }
    &.overlay {
      border: 1px solid rgba($c1, 1);
      color: $c1;
      background-color: rgba($c1, 0.15);
      &.dark {
        border: 1px solid rgba($c1, 1);
        color: $c1;
        background-color: rgba($black1, 1);
      }
    }
    &.primary {
      color: $c1;
      background-color: $yellow1;
      .hiddenText,
      .displayText {
        color: $c1;
      }
    }
    &.text {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    &.icon {
      .suffix {
        @apply overflow-hidden;
        svg {
          transform: translateX(-33px);
        }
      }
    }
    &.textWhite {
      color: var(--black-1);
    }
  }
  &.disabled {
    opacity: 0.5;
  }
}

.black {
  &.button {
    &.primary {
      color: $c1;
      background-color: $black1;
      border: 1px solid $black1;
      .hiddenText,
      .displayText {
        color: $c1;
      }
    }
    .suffix {
      svg {
        path {
          transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
        }
      }
    }
    &.text {
      color: $black1;
      &::after {
        background-color: $black1;
      }
    }
    &:hover:not(.disabled) {
      &.primary {
        color: $black1;
        background-color: transparent;
        .hiddenText,
        .displayText {
          color: $black1;
        }
      }
      .suffix {
        svg {
          path {
            fill: $black1;
          }
        }
      }
    }
  }
}
.yellow {
  &.button {
    &.icon {
      color: $yellow1;
      .suffix {
        svg {
          path {
            fill: $yellow1;
          }
        }
      }
    }
    &.default {
      color: $yellow1;
      border: 1px solid rgba($yellow1, 1);
    }
  }
}
