$bg-color: #2e281f;
$hl-color: #ae905e;
$muted-color: mix(white, $bg-color, 70%);
$trans-time: 300ms;
$width: 320px;

.group {
  position: relative;
  margin: 0.5rem 0;
  &:after {
    content: "\003E";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%) rotate(90deg) scaleY(1.75);
    color: var(--black-1);
    font-size: 12px;
    pointer-events: none;
    z-index: 2;
    transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  }
  input[type="password"] {
    letter-spacing: 0.3em;
  }
  .options {
    @apply absolute left-0 w-full z-30;
    transition: all 250ms cubic-bezier(0.48, 0, 0.12, 1);
    top: 2rem;
    max-height: 0px;
    overflow: hidden;
    background-color: var(--c-1);
    .boxOption {
      .option {
        @apply uppercase block cursor-pointer;
        height: 0;
        color: var(--black-1);
        background-color: var(--c-1);
        font-weight: 450;
        font-size: 0.75rem;
        line-height: 2.5rem;
        padding-left: 1rem;
        letter-spacing: 0.1em;
        transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
        &.selected {
          height: 2.5rem;
          line-height: 2.5rem;
        }
        &:hover {
          color: var(--c-1);
          background-color: var(--black-1);
        }
        &.optionCapitalize {
          @apply capitalize;
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
    }
  }
  // .dropdown,
  input {
    background: none;
    color: var(--black-1);
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(#2e281f, 0.3);
    &:focus {
      outline: none;
    }

    // &:focus ~ label {
    //   // &:valid ~ label {
    //   top: -1rem;
    //   font-size: 12px;
    //   color: $hl-color;
    // }
    // &:focus ~ .bar:before {
    //   width: 100%;
    // }
    // &:focus ~ .options {
    //   max-height: 1000px;
    //   top: 2.8rem;
    //   .option {
    //     height: 40px;
    //   }
    // }
  }
  input.dark {
    border-bottom: 1px solid rgba(#f7eedf, 0.3);
    color: var(--primary-color);
  }

  &.active {
    & > label:not(.placeholderNoneAmin) {
      top: -1rem;
      font-size: 12px;
      color: $hl-color;
    }
    .options {
      max-height: 1000px;
      top: 2.8rem;
      .boxOption {
        .option {
          height: 40px;
        }
      }
    }
    .bar:before {
      width: 100%;
    }
    &:after {
      transform: translate(-50%, -50%) rotate(90deg) scaleX(-1) scaleY(1.75);
    }
  }
  &.valid {
    & > label:not(.placeholderNoneAmin) {
      top: -1rem;
      font-size: 12px;
      color: $hl-color;
    }
  }
  .bar {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      content: "";
      height: 1px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $hl-color;
      transition: $trans-time ease all;
      left: 0%;
    }
  }
  & > label {
    color: var(--black-1);
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: $trans-time ease all;
  }
  & > label.dark {
    color: var(--primary-color);
  }

  &.dark {
    input {
      color: var(--c-1);
      // border: 1px solid var(--c-1);
    }
    &:after {
      color: var(--c-1);
    }
    &.border {
      border: 1px solid var(--c-1);
      &:after {
        color: var(--c-1) !important;
      }
    }
  }
  &.border {
    border: 1px solid rgba(46, 40, 31, 0.3);
    input {
      border-bottom: none;
      padding: 0.625rem !important;
    }
    .bar {
      display: none;
    }
    &:after {
      color: var(--black-1) !important;
    }
  }
}
.dark {
  &:after {
    color: var(--primary-color);
  }
}
