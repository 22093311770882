.section {
  .label {
  }
  .content {
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    max-height: 0;
    overflow: hidden;
  }
  &.showDesktop,
  &.expanded {
    .content {
      max-height: 1000px;
    }
  }
}
// @media (max-width: 1280px) {
// }
// @media (max-width: 1023px) {
// }
@media (max-width: 576px) {
  .section {
    &.showDesktop {
      .content {
        max-height: 0;
      }
    }
    &.expanded {
      &.showDesktop {
        .content {
          max-height: 50rem;
        }
      }
    }
  }
}
