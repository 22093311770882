.section {
  padding-top: 4rem;
  .title {
    @apply text-black-1;
  }
  .content {
    padding-top: 2rem;
    @apply text-black-1;
  }
}

// @media (max-width: 1280px) {
// }
// @media (max-width: 1023px) {
// }
@media (max-width: 576px) {
}
