.section {
  padding-top: 3.5rem;
  > div {
    padding-top: 3.125rem;
    &:first-child {
      padding-top: 0;
    }
  }
  .box {
    @apply flex flex-wrap justify-between;
    .title {
      @apply flex whitespace-nowrap;
      width: 28%;
      min-width: 330px;
      margin-bottom: 1.5rem;
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .list {
      margin-left: 1rem;
      width: calc(72% - 1rem);
      color: var(--black-1);
      .bullet {
        display: flex;
        font-weight: 400;
        line-height: 1.5625rem;
        &:before {
          content: "•";
          font-size: 22px;
          line-height: 18px;
          color: var(--black-1);
          margin-right: 0.5rem;
        }
      }
    }
  }
}
// @media (max-width: 1280px) {
// }
@media (max-width: 1023px) {
  .section {
    .box {
      .list {
        width: 100%;
      }
    }
  }
}
@media (max-width: 576px) {
  .section {
    .box {
      .list {
        width: 100%;
      }
    }
  }
}
