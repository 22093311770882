.menuIcon {
  @apply flex items-center;
  width: 6rem;
  position: absolute;
  padding-left: var(--pad-inner-child);
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  margin-top: 40px;
  height: 88px;
  z-index: 100;
}
.box {
  @apply flex;
  .menu {
    @apply relative flex flex-col h-screen z-30;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    width: 58%;
    transform: translateX(-100%);
    // transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    .bgBlack {
      @apply absolute w-full h-screen bg-black;
      background-color: var(--bg-black-color);
      z-index: -1;
    }
    .head {
      @apply absolute flex justify-between;
      margin-top: 20px;
      transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
      width: 100%;

      .language {
        min-width: initial;
        .currentItem {
          label {
            width: 20px;
          }
        }
        .option {
          @apply flex items-center;
        }
      }
    }
    .content {
      @apply flex flex-col gap-10;
      flex: 1;
      .nav {
        @apply relative cursor-pointer;
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.5rem;

        .arrow {
          margin-left: 2rem;
          margin-top: 0.5rem;
        }
        &.active {
          color: var(--yellow-1);
          path {
            fill: var(--yellow-1);
          }
        }
      }
    }
    .foot {
      font-weight: 450;
    }
  }
  .subMenu {
    @apply relative;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    // transform: translateY(100%);
    width: 42%;
    .overlay {
    }
    .subNavs {
      @apply flex flex-col gap-5 mx-auto;
      padding-top: 15.625rem;
      max-width: 640px;
      .nav {
        font-weight: 400;
      }
      .subLabel {
        @apply capitalize text-center;
        font-size: 1.5rem;
        line-height: 1.875rem;
        font-weight: 400;
        color: var(--yellow-1);
        margin-bottom: 2.625rem;
      }
    }
    .imageBox {
      @apply absolute inset-0 w-full h-full;
      transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
      transform: translateY(100%);
      .image {
        @apply absolute inset-0;
        width: 100%;
        height: 100%;
        aspect-ratio: 939 / 900;
      }
    }
  }
}
.animSidebar {
  &.menu {
    transform: translateX(0%);
  }
}
.active {
  .menu {
    width: 30%;
    .head {
      width: 100vw;
    }
  }
  .subMenu {
    width: 70%;
    .imageBox {
      transform: translateY(0%);
    }
    .subNavs {
    }
  }
}
.switch {
  .subMenu {
    width: 70%;
    .imageBox {
      transform: translateY(100%);
    }

    .subNavs {
    }
  }
}

// @media (max-width: 1280px) {
// }
// @media (max-width: 1023px) {
// }
@media (max-width: 576px) {
  .box {
    @apply hidden;
  }
  .menuIcon {
    @apply flex items-center justify-end;
    padding-right: var(--pad-inner-child);
    right: 0.25rem;
    margin-top: 0;
  }
}
